import React, {useContext} from 'react'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import yellow_dotes from '../../assets/images/yellow_dotes.png'
import downloadScreen from '../../assets/images/downloadScreen.png'
import googleplay from '../../assets/images/googleplay.png'
import appstorebtn from '../../assets/images/appstorebtn.png'
import QuestionModalContext from "../../Utils/questionModal/QuestionModalContext";

const Textslider = {
    responsive: {
        0: {
            items: 2
        },
        600: {
            items: 3
        },
        1000: {
            items: 4
        }
    }
}

function Text() {
    const questionModalContext = useContext(QuestionModalContext);

    return (
        <>
            <div className="text_list_section row_am downaload_section" data-aos="fade-in" data-aos-duration={1500} >
                <div className="container">
                    <div className="yellow_dotes">
                        <img src={yellow_dotes} alt="image" />
                    </div>
                    <div className="center_screen">
                        <div className="img">
                            <img src={downloadScreen} style={{maxWidth: "469px", width: "100%"}} alt="image" />
                        </div>
                        <ul className="app_btn">
                            <li>
                                <Link to={"#"}
                                      onClick={e => {
                                          e.preventDefault();
                                          questionModalContext.open("button01");
                                      }}>
                                    <img className="blue_img" src={googleplay} alt="image" />
                                </Link>
                            </li>
                            <li>
                                <Link to={"#"}
                                      onClick={e => {
                                          e.preventDefault();
                                          questionModalContext.open("button07");
                                      }}>
                                    <img className="blue_img" src={appstorebtn} alt="image" />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="background_slider">
                    <div className="dowanload_slider">
                        <div className="downlist">
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                        </div>
                    </div>
                    <div className="slider_block">
                        <Swiper className="Swiper-Text" id="text_list_flow_download" {...Textslider} slidesPerView={3} >
                            <SwiperSlide className="item">
                                <div className="text_block">
                                    <span>Download </span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="text_block">
                                    <span>Download </span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="text_block">
                                    <span>Download </span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="text_block">
                                    <span>Download </span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="text_block">
                                    <span>Download </span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="text_block">
                                    <span>Download </span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="dowanload_slider">
                        <div className="downlist">
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                            <div className="text_block">
                                <span>Download </span>
                                <span className="mark_star">•</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Text