import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import service1 from '../../assets/images/service1.png'
import service2 from '../../assets/images/service2.png'
import service3 from '../../assets/images/service3.png'
import QuestionModalContext from "../../Utils/questionModal/QuestionModalContext";

function Service() {
    const questionModalContext = useContext(QuestionModalContext);


    return (
        <>
            <section className="row_am service_section">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge mb-1">Služby</span>
                        <h2>
                            Stavíme na <span>kvalitním</span> <br />
                            obsahu
                        </h2>
                    </div>
                    <div className="row service_blocks">
                        <div className="col-md-6">
                            <div className="service_text" data-aos="fade-up" data-aos-duration={1500} >
                                <div className="service_badge">
                                    <i className="icofont-tasks-alt" />
                                    <span>Vzdělávejte se</span>
                                </div>
                                <h2>
                                    Naše <span>filozofie </span> je jasná! Stavíme na <span>vědeckých</span> důkazech.
                                </h2>
                                <p>
                                    Oblast zdraví a zdravého životního stylu je plná “odborníků”, kteří každý tvrdí něco jiného. Náš tým má zkušenosti s prací s vědeckými články, proto jsou naše data a doporučení vždy podložená.
                                </p>
                                <ul className="listing_block">
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-ui-check" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <h3>Potvrzené vědou</h3>
                                            <p>
                                            </p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-ui-check" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <h3>Srozumitelné pro všechny</h3>
                                            <p>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_block">
                                    <Link   to={"#"}
                                            onClick={e => {
                                                e.preventDefault();
                                                questionModalContext.open("button05");
                                            }}
                                            className="btn puprple_btn ml-0">
                                        Chci se vzdělávat
                                    </Link>
                                    <div className="btn_bottom" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                                <img src={service1} alt="image" />
                            </div>
                        </div>
                    </div>
                    <div className="row service_blocks flex-row-reverse">
                        <div className="col-md-6">
                            <div className="service_text right_side"
                                data-aos="fade-up" data-aos-duration={1500} >
                                <div className="service_badge">
                                    <i className="icofont-ui-clock" />
                                    <span>Komplexní programy</span>
                                </div>
                                <h2>
                                    Vyberte si <span>program</span> a vyřešte, co vás <span>trápí</span>
                                </h2>
                                <p>
                                    Odborníky sestavené programy, které vás zasvětí do problematiky, vzdělají vás a postupně se naučíte, co změnit. Ať už vás trápí stres, spánek nebo nadváha, v Melbe najdete program pro vás, který vás naučí, jak se problému zbavit.
                                </p>
                                <ul className="feature_list">
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>vzdělání k tématu</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>postupná tvorba zdravých návyků</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>mechanismus kontroly a sebereflexe</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_block">
                                    <Link   to={"#"}
                                            onClick={e => {
                                                e.preventDefault();
                                                questionModalContext.open("button10");
                                            }}
                                            className="btn puprple_btn ml-0">
                                        Chci se zapsat
                                    </Link>
                                    <div className="btn_bottom" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                                <img src={service2} alt="image" />
                            </div>
                        </div>
                    </div>
                    <div className="row service_blocks">
                        <div className="col-md-6">
                            <div
                                className="service_text"
                                data-aos="fade-up"
                                data-aos-duration={1500}
                            >
                                <div className="service_badge">
                                    <i className="icofont-list" /> <span>Monitoring</span>
                                </div>
                                <h2>
                                    <span>Insight </span> &amp; Streaky pro zvednutí motivace
                                </h2>
                                <p>
                                    Udržet si zdravé návyky dlouhodobě opravdu není jednoduché, ale je to zásadní pro jejich pozitivní vliv. Melbe vás bude pomocí různých motivačních funkcí udržovat u zdravé rutiny. Kromě toho uvidíte i přehledně, jak se vám daří a na základě vašich metrik budete mít personalizovaná doporučení.
                                </p>
                                <ul className="feature_list">
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Vývoj každodenního zhodnocení</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Nejdelší streaky napříč úkolami</p>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <span>
                                                <i className="icofont-check-circled" />
                                            </span>
                                        </div>
                                        <div className="text">
                                            <p>Grafové znázornění pro přehlednost</p>
                                        </div>
                                    </li>
                                </ul>
                                <div className="btn_block">
                                    <Link  to={"#"}
                                           onClick={e => {
                                               e.preventDefault();
                                               questionModalContext.open("button15");
                                           }}
                                           className="btn puprple_btn ml-0">
                                        Chci se zlepšovat
                                    </Link>
                                    <div className="btn_bottom" />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="img" data-aos="fade-up" data-aos-duration={1500}>
                                <img src={service3} alt="image" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Service