import React from 'react';
import startit from '../../assets/images/startit.svg';
import datasession from '../../assets/images/vektor_horizontalni.svg';

const clientLogos = [
    startit,
    datasession,
];

function Clients() {
    return (
        <>
            <section className="row_am our_client">
                <div className="container">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                        <span className="title_badge">Naši partneři</span>
                        <h2>
                            <span className="d-block"></span>
                            Spolupracujeme s
                        </h2>
                    </div>
                    <ul className="client_list">
                        {clientLogos.map((logo, index) => (
                            <li key={index}>
                                <div className="client_logo" data-aos="fade-up" data-aos-duration={1500}>
                                    <img src={logo} alt="image" />
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </section>
        </>
    );
}

export default Clients;
