import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom'
import Bluedots from '../../assets/images/blue_dotes.png'
import QuestionModalContext from "../../Utils/questionModal/QuestionModalContext";
import {handleClickContact} from "../Header/Main";

function Pricing() {

    const [tabMenu, tabActive] = useState({ a: true });
    const questionModalContext = useContext(QuestionModalContext);

    return (
        <>
            <section className="row_am pricing_section white_text" data-aos="fade-in" data-aos-duration={1500} id="pricing_section" >
                <div className="pricing_inner">
                    <div className="container">
                        <div className="dotes_blue">
                            <img src={Bluedots} alt="image" />
                        </div>
                        <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300} >
                            <span className="title_badge">Cena</span>
                            <h2>Kolik to stojí?</h2>
                        </div>
                        <ul className="nav nav-tabs" id="myTab" role="tablist" data-aos="fade-up" data-aos-duration={1500} >
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${tabMenu.a && "active"}`}
                                    onClick={() => tabActive({ a: true })} id="monthly-tab" data-toggle="tab" data-target="#monthly"
                                    type="button" role="tab" aria-controls="monthly" aria-selected="true" >
                                    Měsíčně
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={`nav-link ${tabMenu.b && "active"}`}
                                    onClick={() => tabActive({ b: true })} id="yearly-tab" data-toggle="tab" data-target="#yearly" type="button" role="tab" aria-controls="yearly" aria-selected="false" >
                                    Ročně
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className={`tab-pane fade ${tabMenu.a && "show active"}`} id="monthly" role="tabpanel" aria-labelledby="monthly-tab" >
                                <div className="pricing_pannel">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="pannel_block highlited_block" data-aos="fade-up" data-aos-duration={1500} >
                                                <div className="heading">
                                                    <span className="offer">Ušetříte přes 80 %</span>
                                                    <h3>Členství v předprodeji!</h3>
                                                    <span>Specíální nabídka na jednorázové členství</span>
                                                </div>
                                                <div className="pricing">
                                                    <h3>
                                                        299 Kč <span style={{marginLeft: 3}}>za&nbsp;12&nbsp;měsíců</span>
                                                    </h3>
                                                </div>
                                                <ul className="features">
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                        <p>Budete první, kdo získá aplikaci</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                        <p>Členství platí 12 měsíců od vypuštění aplikace</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                        <p>Ovlivníte, jak bude aplikace vypadat</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                        <p>Podpoříte tým Melbe</p>
                                                    </li>
                                                </ul>
                                                <div className="btn_block white_btn">
                                                    <form action="/api/create-checkout-session" method="POST">
                                                        <button type="submit" className="btn puprple_btn ml-0">
                                                            Koupit členství v předprodeji
                                                        </button>
                                                        <div className="btn_bottom" />
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="pannel_block" data-aos="fade-up" data-aos-duration={1500} >
                                                <div className="heading">
                                                    <span className="offer">Spuštění léto 2024</span>
                                                    <h3>Členství</h3>
                                                    <span>Standardní cena</span>
                                                </div>
                                                <div className="pricing">
                                                    <h3>
                                                        129 Kč <span>/ měsíčně</span>
                                                    </h3>
                                                </div>
                                                <ul className="features">
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                        <p>Komplexní programy na sebezlepšení</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                        <p>Přístup k odborným článkům</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                        <p>Meření pokroku napříč pilířemi</p>
                                                    </li>
                                                    <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                        <p>Návykový systém odměn</p>
                                                    </li>
                                                </ul>
                                                <div className="btn_block">
                                                    <Link to={"#"}
                                                          onClick={e => {
                                                              e.preventDefault();
                                                              questionModalContext.open("button04");
                                                          }} className="btn puprple_btn ml-0">
                                                        Zvolit členství
                                                    </Link>
                                                    <div className="btn_bottom" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane fade ${tabMenu.b && "show active"}`} id="yearly" role="tabpanel" aria-labelledby="yearly-tab" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="pannel_block highlited_block">
                                            <div className="heading">
                                                <span className="offer">Ušetříte přes 70 %</span>
                                                <h3>Členství v předprodeji!</h3>
                                                <span>Specíální nabídka na jednorázové členství</span>
                                            </div>
                                            <div className="pricing">
                                                <h3>
                                                    299 Kč <span style={{marginLeft: 3}}>za&nbsp;12&nbsp;měsíců</span>
                                                </h3>
                                            </div>
                                            <ul className="features">
                                                <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                    <p>Budete první, kdo získá aplikaci</p>
                                                </li>
                                                <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                    <p>Členství platí 12 měsíců od vypuštění aplikace</p>
                                                </li>
                                                <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                    <p>Ovlivníte, jak bude aplikace vypadat</p>
                                                </li>
                                                <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                    <p>Podpoříte tým Melbe</p>
                                                </li>
                                            </ul>
                                            <div className="btn_block white_btn">
                                                <form action="/api/create-checkout-session" method="POST">
                                                    <button type="submit" className="btn puprple_btn ml-0">
                                                        Koupit členství v předprodeji
                                                    </button>
                                                    <div className="btn_bottom" />
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="pannel_block">
                                            <div className="heading">
                                                <span className="offer">Spuštění léto 2024</span>
                                                <h3>Členství</h3>
                                                <span>Standardní cena</span>
                                            </div>
                                            <div className="pricing">
                                                <h3>
                                                    999 Kč <span>/ ročně</span>
                                                </h3>
                                            </div>
                                            <ul className="features">
                                                <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                    <p>Komplexní programy na sebezlepšení</p>
                                                </li>
                                                <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                    <p>Přístup k odborným článkům</p>
                                                </li>
                                                <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                    <p>Meření pokroku napříč pilířemi</p>
                                                </li>
                                                <li>
                                                        <span className="icon">
                                                            <i className="icofont-check-circled" />
                                                        </span>
                                                    <p>Návykový systém odměn</p>
                                                </li>
                                            </ul>
                                            <div className="btn_block">
                                                <Link to={"#"}
                                                      onClick={e => {
                                                          e.preventDefault();
                                                          questionModalContext.open("button06");
                                                      }}
                                                      className="btn puprple_btn ml-0">
                                                    Zvolit členství
                                                </Link>
                                                <div className="btn_bottom" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="contact_text text-center" data-aos="fade-up" data-aos-duration={1500} >
                                Nejste si jistí? <Link to="#" onClick={e => handleClickContact(e)}>Kontaktujte</Link> nás pro individuální přístup.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Pricing