import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

const Textslider = {
    responsive: {
        0: {
            items: 2,
        },
        600: {
            items: 3,
        },
        1000: {
            items: 4,
        },
    },
};

const responsiveSlidesPerView = {
    0: {
        slidesPerView: "auto",
    },
    770:{
        slidesPerView:1.2,
    },
    1200:{
        slidesPerView:2.1,
    }
};

const textItems = [
    'Komplexní péče',
    'Kvalitní obsah',
    'Jednoduchost',
    'Přehlednost',
    'Kvalitnější život',
    'Návykové',
    'Bezstarostné',
];

function Textlist() {
    return (
        <>
            <div className="text_list_section row_am" data-aos="fade-in" data-aos-duration={1500} >
                <div className="container">
                    <span className="title_badge down_fix">Proč si vybrat naší aplikaci</span>
                </div>
                <div className="slider_block">
                    <Swiper className="swiper-wrapper" id="text_list_flow" {...Textslider} breakpoints={responsiveSlidesPerView} slidesPerView={2.1}>
                        {textItems.map((item, index) => (
                            <SwiperSlide key={index} className="item">
                                <div className="text_block">
                                    <span>{item}</span>
                                    <span className="mark_star">•</span>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    );
}

export default Textlist;
