import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import blue_dotes from '../../assets/images/blue_dotes.png'
import keyftr1 from '../../assets/images/keyftr1.png'
import keyftr2 from '../../assets/images/keyftr2.png'
import keyftr3 from '../../assets/images/keyftr3.png'
import keyftr4 from '../../assets/images/keyftr4.png'
import keyftr5 from '../../assets/images/keyftr5.png'
import keyftr6 from '../../assets/images/keyftr6.png'
import {Pagination} from "swiper/modules";

const Feature = {
    responsive: {
        0: {
            items: 1,
        },
        600: {
            items: 2,
        },
        1000: {
            items: 3,
        },
        1400: {
            margin: 60,
        },
    },
};

const responsiveSlidesPerView = {
    0: {
        slidesPerView: 1,
    },
    770:{
        slidesPerView:2,
    },
    1200:{
        slidesPerView:3
    }
};


const featureItems = [
    {
        title: 'Zhodnocení dne',
        description: 'Jednoduše zjistíme, co vás nejvíce trápí, a společně na tom budeme intenzivně pracovat.',
        image: keyftr1,
    },
    {
        title: 'Návykový obsah',
        description: 'Složité koncepty zpracované do srozumitelné a pochopitelné podoby našími odborníky.',
        image: keyftr2,
    },
    {
        title: 'Komplexní programy',
        description: 'Pokud Vás něco trápí, zapište se do jednoho z programů a sledujte, jak vaše problémy zmizí.',
        image: keyftr3,
    },
    {
        title: 'Insight',
        description: 'Pro dlouhodobý úspěch je zapotřebí mít přehled, který uživatele motivuje v práci na sobě.',
        image: keyftr4,
    },
    {
        title: 'Notifikace',
        description: 'Aby uživatel na nic nezapomněl, aplikace se mu formo notifikací vždy ve spravný moment připomene.',
        image: keyftr5,
    },
    {
        title: 'Odměny',
        description: 'Odměny má každý rád a tak za dodržování výzev, vzdělávání a mnoho dalšího získávate body.',
        image: keyftr6,
    },
];

function Keyfeature() {
    return (
        <>
            <section className="key_feature_section row_am pt-0">
                <div className="key_innner">
                    <div className="container">
                        <div className="dotes_blue">
                            <img src={blue_dotes} alt="image" />
                        </div>
                        <div className="section_title">
                            <span className="title_badge">Klíčové funkcionality</span>
                            <h2>Jak to funguje</h2>
                        </div>
                        <Swiper
                            id="feature_slider"
                            className="swiper-wrapper"
                            data-aos="fade-up"
                            data-aos-duration={1500}
                            {...Feature} spaceBetween={15}
                            breakpoints={responsiveSlidesPerView}
                            allowTouchMove={true}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            scrollbar={{ draggable: true }}
                        >
                            {featureItems.map((item, index) => (
                                <SwiperSlide className="item" key={index}>
                                    <div className="feature_box">
                                        <h3>{item.title}</h3>
                                        <p>{item.description}</p>
                                        <div className="img">
                                            <img src={item.image} style={{width: "100%", maxWidth: "270px"}} alt="image" />
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Keyfeature;
