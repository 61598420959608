import React, {useContext, useState} from 'react'
import { Link } from 'react-router-dom'
import { TypeAnimation } from 'react-type-animation';
import Banner1 from '../../assets/images/banavt1.png'
import Banner2 from '../../assets/images/banavt2.png'
import Banner3 from '../../assets/images/banavt3.png'
import Play from '../../assets/images/play.svg'
import googleplay from '../../assets/images/googleplay.png'
import appstorebtn from '../../assets/images/appstorebtn.png'
import smallStar from '../../assets/images/smallStar.png'
import bigstar from '../../assets/images/bigstar.png'
import hero from '../../assets/images/hero_image.png'
import QuestionModalContext from "../../Utils/questionModal/QuestionModalContext";
import {handleClickPrice} from "../Header/Main";

function Banner() {
    const questionModalContext = useContext(QuestionModalContext);

    const [ytban, setytban] = useState(false);

    return (
        <>
            <section className="banner_section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-up" data-aos-duration={1500} >
                            <div className="banner_text">
                                <div className="type-wrap">
                                    <TypeAnimation id="typed"
                                        sequence={['Zlepšete psychiku', 500, 'Začněte dobře spát', 500, 'Vypořádejte se se stresem', 500]}
                                        style={{ whiteSpace: "pre" }} repeat={Infinity} />
                                </div>
                                <h1>
                                    Melbe je Vaše cesta ke zdraví a  <span>dlouhému životu</span>
                                </h1>
                                <p>
                                    Máte problémy se spánkem, špatně se soustředíte nebo špatně zvládate
                                    stres? Svěřte se do péče naší aplikace.
                                </p>
                            </div>

                            <div className="used_app">
                                <ul>
                                    <li className="d-none d-md-inline">
                                        <img src={Banner1} alt="image" />
                                    </li>
                                    <li className="d-none d-md-inline">
                                        <img src={Banner2} alt="image" />
                                    </li>
                                    <li className="d-none d-md-inline">
                                        <img src={Banner3} alt="image" />
                                    </li>
                                    <li>
                                        <div className="btn_block ml-5">
                                            <a className="btn puprple_btn ml-0" to="#pricing_section" onClick={e => handleClickPrice(e)}>
                                                Koupit členství v předprodeji!
                                            </a>
                                            <div className="btn_bottom" />
                                        </div>
                                    </li>
                                </ul>
                                <h3>500 + Natěšených uživatelů</h3>
                                <p>
                                    Jediná aplikace na komplexní zlepšení zdraví,<br /> kterou budete potřebovat.
                                </p>
                            </div>


                            <ul className="app_btn">
                                <li>
                                    <Link to={"#"}
                                          onClick={e => {
                                              e.preventDefault();
                                              questionModalContext.open("button02");
                                          }}
                                    >
                                        <img className="blue_img" src={googleplay} alt="image" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"#"}
                                          onClick={e => {
                                              e.preventDefault();
                                              questionModalContext.open("button3");
                                          }}
                                    >
                                        <img className="blue_img" src={appstorebtn} alt="image" />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="banner_slider">
                                <div className="left_icon">
                                    <img src={smallStar} alt="image" />
                                </div>
                                <div className="right_icon">
                                    <img src={bigstar} alt="image" />
                                </div>
                                <div className="hero_img">
                                    <img src={hero} style={{maxWidth: "469px"}} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {ytban &&
                <div className="modal fade youtube-video show" id="myModal"
                    tabIndex={-1} style={{ display: "block", paddingRight: 17 }}
                    aria-modal="true" role="dialog" >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" onClick={() => setytban(false)}>
                            <button id="close-video"
                                type="button" className="button btn btn-default text-right" data-dismiss="modal" onClick={() => setytban(false)}>
                                <i className="icofont-close-line-circled" />
                            </button>
                            <div className="modal-body">
                                <div id="video-container" className="video-container">
                                    <iframe id="youtubevideo"
                                        width={640} height={360} allowFullScreen=""
                                        src="https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1" />
                                </div>
                            </div>
                            <div className="modal-footer"></div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}

export default Banner