import { useEffect } from "react";
import gtmVirtualPageView from "./gtmVirtualPageView";
import { useLocation } from "react-router-dom";

const GTMProvider = ({ children }) => {
    let location = useLocation();

    useEffect(() => {
        const mainDataLayer = {
            pageTypeName: document.title ?? null,
            url: location
        };

        gtmVirtualPageView(mainDataLayer);
    }, [location]);

    return (
        <>
            {children}
        </>
    );
};

export default GTMProvider;
