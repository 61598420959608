import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from 'swiper/modules';
import Interscrn1 from '../../assets/images/intrscrn1.png'
import Interscrn2 from '../../assets/images/intrscrn2.png'
import Interscrn3 from '../../assets/images/intrscrn3.png'
import Interscrn4 from '../../assets/images/intrscrn4.png'
import Interscrn5 from '../../assets/images/intrscrn5.png'
import Interscrn6 from '../../assets/images/intrscrn6.png'
import Interscrn7 from '../../assets/images/intrscrn7.png'

const interfaceslider = {
    loop: true,
    effect: "coverflow",
    margin: 20,
    centeredSlides: true,
    coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 4,
        slideShadows: true
    },

    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 3
        },
        1000: {
            items: 5
        }
    }
}

const responsiveSlidesPerView = {
    0: {
        slidesPerView: 1,
    },
    770:{
        slidesPerView: 3,
    },
    1200:{
        slidesPerView: 5
    }
};

function Interface() {
    return (
        <>
            <section className="row_am interface_section">
                <div className="container-fluid">
                    <div className="section_title" data-aos="fade-up" data-aos-duration={1500} data-aos-delay={300}>
                        <span className="title_badge">Ukázka applikace</span>
                        <h2>
                            Uživatelsky <span>přívětivý</span> design
                        </h2>
                    </div>
                    <div className="screen_slider" data-aos="fade-up" data-aos-duration={1500}>
                        <Swiper id="screen_slider" className="mySwiper"
                                {...interfaceslider} spaceBetween={20} allowTouchMove={true}
                                modules={[Pagination]}
                                breakpoints={responsiveSlidesPerView}
                                slidesPerView={5}
                                pagination={{ clickable: true }}
                                scrollbar={{ draggable: true }}
                                style={{paddingBottom: "50px"}}>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn1} style={{style: "100%"}} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn2} style={{style: "100%"}} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn3} style={{style: "100%"}} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn4} style={{style: "100%"}} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn5} style={{style: "100%"}} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn6} style={{style: "100%"}} alt="image" />
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className="item">
                                <div className="screen_frame_img">
                                    <img src={Interscrn7} style={{style: "100%"}} alt="image" />
                                </div>
                            </SwiperSlide>
                        </Swiper>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Interface