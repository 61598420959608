import React, {useCallback, useEffect, useState} from "react";
import WindowLocationContext from "./WindowLocationContext";

const QuestionModalProvider = (
    {
        children
    }
) => {
    const [search, setSearch] = useState("none");

    useEffect(()=>{
        setSearch(window.location.search);
    }, []);

    const handleRemoveQueryParameter = useCallback((key)=>{
        const urlObj = new URL(window.location);
        const params = new URLSearchParams(urlObj.search);
        params.delete(key);
        window.location.search = params.toString();
    }, []);

    return (
        <WindowLocationContext.Provider value={{
            search: search,
            removeQueryParameters: handleRemoveQueryParameter
        }}>
            {children}
        </WindowLocationContext.Provider>
    )
};

export default QuestionModalProvider;

