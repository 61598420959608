import React, {useRef, useState} from "react";
import MailerLite from "@mailerlite/mailerlite-nodejs";

const EmailForm = ({ note, type, search, buttonId }) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const formRef = useRef(null)

    const mailerlite = new MailerLite({
        api_key: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNmRjYzk3NzBiOWYxZDZhYzczMzBmMDU0N2ZiMjA1MDQ3MmNmMjk1MTFiZjNiYmQ1Mzg0MmY2MzQ0YTY4NDEzOTI0NDFlYTc2YzMyNjJjYmEiLCJpYXQiOjE3MTE1MzQ5MzEuODYwNzgsIm5iZiI6MTcxMTUzNDkzMS44NjA3ODIsImV4cCI6NDg2NzIwODUzMS44NTczNTgsInN1YiI6IjYzMzkwNiIsInNjb3BlcyI6W119.Qj4s7lMxMvpytkEOLb4cDhFsGDL1F7Aax9Dumdc0l7UvCNbwfiV2BMUz0nXE4pvc2cFBm3aY8sg1nGRemXL9-U523bDVYjqs-fggl4b5hycrmekju6w8p9EqXyeR_i04QL-zaQ9f4mzYot77b5FK9RsrUH6_pZm_FKdM9h_07-VvwP2BzHow_H1Xal4eEEAZ1l2e-Jl6aio_Epl-OpEXFnZ1GywyJIKILch9VogyimHwnLtTZcaEiXLJ9mUHN3eV4MZfSLoBjTL92EeGTW4K_eFOEcNwuhH6F_Eb-RAedzYNLkyPLmi9GcdtewWbTgTCq2P_vKbfw4MJTXBxRPF7y2Mshf-UgFeuYvwyQ6X11Vj1BQYZfyhfxWFD3OnD7CamMrilJpr8hbMWXI2HzSE-iYg3J_NnkvwShGYTfcAiiaTlqW2nxdMuw29eqKXeUsmWSrCu6-G5sVyHNqjvdiXdvoT4hmh8Lc9AJbznSbl9rCYoPXL2oH3QfmlTQT9mZk6RdfmPOrn0fbL4LQfB_nguWs_j2P-BjgiB9xTZiNhRQiZwRKkF_91qi3KfBewVOid7_MqmKJiv9mfgV7jtLnyq5jOxirJOI-3C0Uailmy-r4qBuGKmQskvwVsu4Ifm7JJI6DdsYvBWZP_6V5zRREf7gEC91t9QRx51Ro09FQUZRZc"
    });

    const handleSubmit = async (event) => {
        setLoading(true);
        event.preventDefault();

        console.log("submit");

        const params = {
            email: email,
            location: email,
            fields: {
                company: "LandinPage Form – " + type + " – " + search + " – " + buttonId,
            },
            groups: ["100095071124195098"],
            status: "active",
            source: "api"
        };

        mailerlite.subscribers.createOrUpdate(params)
            .then(response => {
                setEmail("")
                setLoading(false);
                setSent(true);
            })
            .catch(error => {
                if (error.response) console.log(error.response.data);
            });
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit} key={"form" + type}>
            <div className="form-group">
                <input
                    type="email"
                    value={email}
                    required={true}
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Zadejte email"
                />
                <button className="btn" type="submit" disabled={loading}>
                    {
                        loading && (
                            <i className="icofont-spinner" />
                        )
                    }
                    {
                        !loading && (
                            <i className="icofont-paper-plane" />
                        )
                    }
                </button>
            </div>
            <p className="note text-success" style={{marginBottom: 0, height: "1.7rem"}}>
                {sent && "Odesláno!"}
            </p>
            <p className="note">
                {note}
            </p>
        </form>
    )
};

export default EmailForm;
