import React, {useContext, useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import QuestionModalContext from "../../Utils/questionModal/QuestionModalContext";

function Main() {

    const [headerScroll, setheaderScroll] = useState(false)
    const [mobile, setmobile] = useState()
    const [Page, setPage] = useState()
    const questionModalContext = useContext(QuestionModalContext);

    return (
        <>
            <header className={headerScroll ? "fixed fix_style" : "fixed"} >
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <Link className="navbar-brand" to="/">
                            <img src={logo} alt="Logo" />
                        </Link>
                        <button className={`navbar-toggler ${mobile && "collapsed"}`} onClick={() => setmobile(mobile === true ? false : true)} type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <div className={`toggle-wrap ${mobile && "active"}`} onClick={() => setmobile(mobile === true ? false : true)}>
                                <span className="toggle-bar"></span>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item active">
                                    <Link className="nav-link" to="#home_section" onClick={e => handleClickHome(e)}>
                                        Domů
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link className="nav-link" to="/about">*/}
                                {/*        O nás*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item has_dropdown">*/}
                                {/*    <Link className="nav-link" to="#" onClick={() => setPage(!Page)}>*/}
                                {/*        Pages{" "}*/}
                                {/*    </Link>*/}
                                {/*    <span className={Page ? "drp_btn rotate" : "drp_btn "} onClick={() => setPage(!Page)}>*/}
                                {/*        <i className="icofont-rounded-down" />*/}
                                {/*    </span>*/}
                                {/*    <div className="sub_menu">*/}
                                {/*        <ul>*/}
                                {/*            <li>*/}
                                {/*                <Link to="/blog-list">Blog List</Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to="/blog-detail">Single Blog</Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to="/sign-in">Sign In</Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to="/sign-up">Sign Up</Link>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link" to="#pricing_section" onClick={e => handleClickPrice(e)}>
                                        Cena
                                    </Link>
                                </li>
                                {/*<li className="nav-item">*/}
                                {/*    <Link className="nav-link" to="/reviews">*/}
                                {/*        Recenze*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                {/*<li className="nav-item">*/}
                                {/*    <Link className="nav-link" to="/blog-list">*/}
                                {/*        Blog*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                                <li className="nav-item">
                                    <Link className="nav-link" to="#contact_section" onClick={e => handleClickContact(e)}>
                                        Kontakt
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <div className="btn_block">
                                        <Link className="nav-link dark_btn" to={"#"} onClick={e => {
                                            e.preventDefault();
                                            questionModalContext.open("button12");
                                        }}>
                                            Mám zájem
                                        </Link>
                                        <div className="btn_bottom" />
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {mobile &&
                            <>
                                <div className={`navbar-collapse collapse ${mobile && "show"}`} id="navbarSupportedContent" style={{}} >
                                    <ul className="navbar-nav ml-auto">
                                        <li className="nav-item active">
                                            <Link className="nav-link"  to="#home_section" onClick={e => handleClickHome(e)}>
                                                Domů
                                            </Link>
                                        </li>
                                        {/*<li className="nav-item">*/}
                                        {/*    <Link className="nav-link" to="/about">*/}
                                        {/*        About us*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item has_dropdown">*/}
                                        {/*    <Link className="nav-link" to="#">*/}
                                        {/*        Pages*/}
                                        {/*    </Link>*/}
                                        {/*    <span className="drp_btn">*/}
                                        {/*        <i className="icofont-rounded-down" />*/}
                                        {/*    </span>*/}
                                        {/*    <div className="sub_menu">*/}
                                        {/*        <ul>*/}
                                        {/*            <li>*/}
                                        {/*                <Link to="/blog-list">Blog List</Link>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <Link to="/blog-detail">Single Blog</Link>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <Link to="/sign-in">Sign In</Link>*/}
                                        {/*            </li>*/}
                                        {/*            <li>*/}
                                        {/*                <Link to="/sign-up">Sign Up</Link>*/}
                                        {/*            </li>*/}
                                        {/*        </ul>*/}
                                        {/*    </div>*/}
                                        {/*</li>*/}
                                        <li className="nav-item">
                                            <Link className="nav-link" to="#pricing_section" onClick={e => handleClickPrice(e)}>
                                                Cena
                                            </Link>
                                        </li>
                                        {/*<li className="nav-item">*/}
                                        {/*    <Link className="nav-link" to="/reviews">*/}
                                        {/*        Reviews*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                        {/*<li className="nav-item">*/}
                                        {/*    <Link className="nav-link" to="/blog-list">*/}
                                        {/*        Blog*/}
                                        {/*    </Link>*/}
                                        {/*</li>*/}
                                        <li className="nav-item">
                                            <Link className="nav-link" to="#contact_section" onClick={e => handleClickContact(e)}>
                                                Kontakt
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <div className="btn_block">
                                                <Link className="nav-link dark_btn"
                                                      to={"#"}
                                                      onClick={e => {
                                                          e.preventDefault();
                                                          questionModalContext.open("button17");
                                                      }}
                                                >
                                                    Mám zájem
                                                </Link>
                                                <div className="btn_bottom" />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                    </nav>
                </div >
            </header>
        </>
    )
}

export default Main


export function handleClickHome(evt) {
    const ele = document.querySelector('#home_section');
    window.scrollTo({
        behavior: "smooth",
        top: ele.offsetTop
    })
}
export function handleClickPrice(evt) {
    const ele = document.querySelector('#pricing_section');
    window.scrollTo({
        behavior: "smooth",
        top: ele.offsetTop
    })
}
export function handleClickContact(evt) {
    const ele = document.querySelector('#contact_section');
    window.scrollTo({
        behavior: "smooth",
        top: ele.offsetTop
    })
}