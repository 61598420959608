import React, {useCallback, useContext, useState} from "react";
import QuestionModalContext from "./QuestionModalContext";
import Mobileicon from "../../assets/images/mobileicon.png";
import EmailForm from "../forms/EmailForm";
import WindowLocationContext from "../window/WindowLocationContext";



const QuestionModalProvider = (
    {
        children
    }
) => {
    const windowLocationContext = useContext(WindowLocationContext);

    const [questionsModal, setQuestionsModal] = useState(false);
    const [buttonId, setButtonId] = useState("buttonNONE");

    const handleOpen = useCallback((butId)=>{
        setQuestionsModal(true);
        setButtonId(butId ?? "buttonUNDEFINED");
    }, []);

    const handleClose = useCallback(()=>{
        setQuestionsModal(false);
    }, []);

    return (
        <QuestionModalContext.Provider value={{
            isOpen: questionsModal,
            open: handleOpen,
            close: handleClose
        }}>
            {children}
            {
                questionsModal && (
                    <div className="modal fade show main-validation-modal" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" onClick={handleClose}>
                        <div className="modal-dialog modal-dialog-scrollable"
                             style={{maxWidth: "750px"}}
                             onClick={(e)=>e.stopPropagation()}
                        >
                            <div className="modal-content main-validation-modal-content"
                                 style={{
                            }}>
                                <div className="modal-body">

                                    <div>
                                        <div className="section_title" data-aos="fade-up" data-aos-duration={1500}>
                                            <h2>
                                                Na aplikaci <span>usilovně</span> pracujeme<span>!</span>{" "}
                                                <img src={Mobileicon} alt="image" />
                                            </h2>
                                        </div>
                                    </div>

                                    <br/>

                                    <div className="service_section">
                                        <div className="service_text" data-aos-duration={1500} style={{paddingRight: "0px", textAlign: "center"}}>
                                            <h2>
                                                Pokud vás aplikace zaujala, <span>nezapomeňte </span>zanechat svůj <span>email</span>
                                            </h2>
                                        </div>
                                    </div>

                                    <div className="modaFooter">
                                        <div className="news_letter">
                                            <EmailForm type="modalForm" note="Buďte první, kdo se dozví vše podstatné" search={windowLocationContext.search} buttonId={buttonId} />
                                        </div>
                                    </div>

                                    <br/>


                                    <div className="service_section">
                                        <div className="service_text" data-aos-duration={1500} style={{paddingRight: "0px", textAlign: "center"}}>
                                            <h2>
                                                Chcete nás podpořit? Kupte si <span>členství na 12 měsíců</span> v předprodeji.
                                            </h2>
                                            <div className="btn_block aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500" style={{marginTop: "0px"}}>
                                                <form action="/api/create-checkout-session" method="POST">
                                                    <button type="submit" className="btn puprple_btn ml-0">
                                                        Koupit členství za 299 Kč
                                                    </button>
                                                    <div className="btn_bottom" />
                                                </form>
                                            </div>

                                            <br/>
                                            <p className="note">
                                                <strong>Se slevou 80 %</strong>
                                            </p>
                                        </div>
                                    </div>


                                    <div style={{textAlign: "right", width: "100%"}}>
                                        <div className="btn-link" onClick={handleClose} style={{color: "black", cursor: "pointer"}}>
                                            Zavřít
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        </QuestionModalContext.Provider>
    )
};

export default QuestionModalProvider;

