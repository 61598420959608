import React, {useContext} from 'react'
import { Link } from 'react-router-dom'
import Bluedots from '../../assets/images/blue_dotes.png'
import Step1 from '../../assets/images/howstep1.png'
import Step2 from '../../assets/images/howstep2.png'
import Step3 from '../../assets/images/howstep3.png'
import QuestionModalContext from "../../Utils/questionModal/QuestionModalContext";


function HowWork() {
    const questionModalContext = useContext(QuestionModalContext);

    return (
        <>
            <section className="how_it_section white_text">
                <div className="how_it_inner" data-aos="fade-in" data-aos-duration={1500}>
                    <div className="dotes_blue">
                        <img src={Bluedots} alt="image" />
                    </div>
                    <div className="container">
                        <div className="section_title"
                            data-aos="fade-up" data-aos-duration={1500} >
                            <span className="title_badge">Rychle &amp; jednoduše</span>
                            <h2>Jak to funguje ve 3 krocích</h2>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <div className="steps_block step_border"
                                    data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="steps">
                                        <div className="icon">
                                            <img src={Step1} alt="image" />
                                        </div>
                                        <div className="text">
                                            <h3>Stahněte si appku</h3>
                                            <ul className="social">
                                                <li>
                                                    <Link   to={"#"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                questionModalContext.open("button08");
                                                            }}>
                                                        <i className="icofont-brand-android-robot" />
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link   to={"#"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                questionModalContext.open("button11");
                                                            }}>
                                                        <i className="icofont-brand-apple" />
                                                    </Link>
                                                </li>
                                            </ul>
                                            <p>
                                                Stáhněte si appku. Funguje na <br /> Androidu &amp; iOS
                                            </p>
                                        </div>
                                    </div>
                                    <span className="step">01</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="steps_block step_border" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="steps">
                                        <div className="icon">
                                            <img src={Step2} alt="image" />
                                        </div>
                                        <div className="text">
                                            <h3>Vytvořte si účet</h3>
                                            <span className="tag_text">Zdarma prvních 14 dnů</span>
                                            <p>
                                                Aplikace je ZDARMA prvních 14 dní. Vyzkoušejte si ji.
                                            </p>
                                        </div>
                                    </div>
                                    <span className="step">02</span>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="steps_block" data-aos="fade-up" data-aos-duration={1500} >
                                    <div className="steps">
                                        <div className="icon">
                                            <img src={Step3} alt="image" />
                                        </div>
                                        <div className="text">
                                            <h3>Užívejte aplikaci</h3>
                                            <span className="tag_text">Přečtěte si FAQ</span>
                                            <p>
                                                Užívejte aplikaci &amp; zlepšujte <br /> své zdraví
                                            </p>
                                        </div>
                                    </div>
                                    <span className="step">03</span>
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="btn_block">
                                <Link to={"#"}
                                      onClick={e => {
                                          e.preventDefault();
                                          questionModalContext.open("button16");
                                      }} className="btn puprple_btn ml-0">
                                    Začněte teď
                                </Link>
                                <div className="btn_bottom" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HowWork